import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdSend, MdMic, MdAttachFile } from 'react-icons/md';

const Footer = props => {

    const [message, setMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        if (message.trim() !== '') {
            props.onMessage(message);
            setMessage('');
        } else {
            // getMicrophone();
        }
    }

    const sendFile = (f) => {
        const types = ['image/png', 'image/jpeg', 'image/gif'];
        if (!types.every(type => f.type !== type)) {
            props.onFile(f);
        }
    }

    const startRecording = (e) => {
        if (window.ReactNativeWebView && message.trim() === '' && !isRecording) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'startRecording'
            }));
            setIsRecording(true);
        } 
    }

    const stopRecording = (e) => {
        if (window.ReactNativeWebView && message.trim() === '') {
            setTimeout(function() {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: 'stopRecording'
                }));
                setIsRecording(false);
            }, 1000);
        }
    }

    return (
        <>
        { props.suggestions.length > 0 ?
            <div className={`suggestions-row ${props.suggestions.length < 3 ? "suggestions-fix" : ""}`}>
                {
                    props.suggestions.map((s, i) => {
                        return (
                            <button key={i} className="suggestion" onClick={e => props.onMessage(`${s}`)}><div><span>{s}</span></div></button>
                        )
                    })
                }
            </div> : <></>
        }
        <div className="footer-bar">
            <form className="conversation-compose" onSubmit={(e) => submit(e)}>
                <input className="input-msg" name="input" value={message} onChange={e => setMessage(e.target.value)} placeholder={`${isRecording ? "Gravando..." : "Digite uma mensagem..."}`} autoComplete="off" autoFocus disabled={isRecording}></input>
                <button className="send" onTouchCancel={e => stopRecording(e)} onTouchStart={e => startRecording(e)} onTouchEnd={e => stopRecording(e)}>
                    <div className={`circle ${isRecording ? "red-circle" : ""}`}>
                        {/* <MdSend></MdSend> */}
                        { message.trim() === '' && (window.ReactNativeWebView && window.supportsAudioRecording) ?  <MdSend/> : <MdSend/> }
                    </div>
                  </button>
            </form>
        </div>
        </>
    );
};



Footer.propTypes = {
    onMessage: PropTypes.func.isRequired,
    onFile: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired
};

export default Footer;