import React, { useRef, useEffect, useState } from 'react';
import { CONTENTTYPE, MESSAGETYPE, STATUS } from '../../../../store/appReducer';
import { Anchorme } from 'react-anchorme'
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const MessagesList = props => {

    const secDivRef = useRef(null);
    const [showImage, setShowImage] = useState(null);

    useEffect(() => {
        window.InitAudioPlayers();
        if (secDivRef.current) {
            setTimeout(function() {
                secDivRef.current.scroll(0, secDivRef.current.scrollHeight + 100);
            }, 10);
        }
    });

    const scrollDown = () =>  {
        if (secDivRef.current) {
            setTimeout(function() {
                secDivRef.current.scroll(0, secDivRef.current.scrollHeight + 100);
            }, 10);
        }
    }

    const renderMessage = (msg) => {
        switch(msg.contentType) {
            case "audio":
                return (<>
                <p className="message-text">
                    <div id="audioplayer" className="n-audio-player" data-src={msg.text} data-size="small"></div>
                </p>
                </>)
            case "image":
                return(<>
                    <p className="message-image"><img onLoad={scrollDown} alt="" onClick={(e) => setShowImage(e.target.src)} src={msg.text}></img></p>
                    { showImage && 
                        <Lightbox mainSrc={showImage} onCloseRequest={() => setShowImage(null)}
                            toolbarButtons={[
                                <a href={showImage} rel="noopener noreferrer" target="_blank" download>
                                    <button className="ril-download ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__downloadButton"></button>
                                </a>
                            ]}>
                        </Lightbox> 
                    }
                </>);
            case "list":
                return(<>
                    <span>{JSON.parse(msg.text).map(m => `${m.title}\n${m.description}\n\n`)}</span>
                    {/* ${m.image} tem a imagem do item do carrossel */}
                </>);
            case "text":
            default:
                return (<>
                    <p className="message-text"><Anchorme target="_blank" rel="noreferrer noopener">{msg.text}</Anchorme></p>
                    <p className="message-info">
                        <span className="message-time">{new Date(msg.timestamp).getHours().toString().padStart(2, "0") + ":" + new Date(msg.timestamp).getMinutes().toString().padStart(2, "0")}</span>
                        <span className={(() => {
                            switch(msg.status) {
                                case STATUS.SENT:
                                    return "message-status sent";
                                case STATUS.RECEIVED:
                                    return "message-status read";
                                case STATUS.ERROR:
                                case STATUS.SENDING:
                                    return "message-status sending";
                                default:
                                    return ""
                            }
                        })()}></span>
                    </p>
                </>)
        }
    }

    return (
        <div className={`messages-container${props.extraMargin ? '-extra' : ''}`} ref={secDivRef}>
            <div className={"messages"}>
                { props.messages.map((msg, i) => {
                    return (
                        <div key={i} className={msg.type === MESSAGETYPE.INCOMING ? "message left" : `message right ${msg.contentType === CONTENTTYPE.AUDIO ? 'audio' : ''}`}>
                            <div className={`message-balloon ${msg.contentType === CONTENTTYPE.AUDIO ? 'audio' : ''}`}>
                                { renderMessage(msg) }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div> 
    );
};

const formatMessageText = (msg) => {
    (msg.match(/\*.*?\*/g) || []).forEach(q => {
        msg = msg.replace(q, `<strong>${q.replace(/\*/g, "")}</strong>`);
    });
    msg = msg.replace(/(\r\n|\n|\r)/gm,"<br>");
    return msg;
};

MessagesList.propTypes = {
    messages: PropTypes.array.isRequired,
    extraMargin: PropTypes.bool.isRequired,
};

export default MessagesList;