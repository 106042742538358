import React from 'react'

import { Switch, Route  } from 'react-router-dom'

import { Main as MainView } from './views/'

export default () => {
    return (
        <Switch>
            <Route path="/" component={MainView} />
        </Switch>
    )
}