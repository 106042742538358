import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { keepAlive, getMessages, sendMessage, sendFile } from '../../store/appReducer';
import { MessagesList, Header, LoadingCircle, Footer } from './components';
import './ChatView.scss';

const ChatView = (props) => {
    
    useEffect(() => {
        props.getMessages();
        setInterval(() => props.keepAlive(), 10000);
        // eslint-disable-next-line
    }, []);

    window.updatePid = (v) => {
        console.log("Exchanging pid");
        localStorage.setItem("pid", v);
        props.getMessages(true);
    }

    window.sendAudio = (base64Audio) => {
        console.log("Receiving user audio");
        let arr = `data:audio/wav;base64,${base64Audio}`.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        props.sendFile(new File([u8arr], new Date().toISOString(), { type:mime }), true);
    }

    return (
        <div className="appcontainer">
            <Header name={process.env.REACT_APP_BOT} color={process.env.REACT_APP_COLOR}></Header>
            { props.isLoading ? 
                <LoadingCircle/>
            : 
                <MessagesList messages={props.messages} extraMargin={props.suggestions.length > 0}></MessagesList>
            }
            <Footer onMessage={props.sendMessage} onFile={props.sendFile} suggestions={props.suggestions}></Footer>
            {/* <button onClick={() => props.getMessages()}>Recuperar</button>
            <button onClick={() => props.sendMessage("Olá")}>Enviar</button> */}
        </div>
    )
};

const mapStateToProps = state => ({
    messages: state.app.messages,
    suggestions: state.app.suggestions,
    isLoading: state.app.isLoading
});

const mapDispatchToProps = dispatch => bindActionCreators({ getMessages, sendMessage, sendFile, keepAlive }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatView);