import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const api = axios.create( { baseURL: process.env.REACT_APP_ENDPOINT } );
let ws;

const DEFAULT_STATE = {
    messages: [],
    suggestions: [],
    isLoading: true
}

export const ACTIONS = {
    GET_MESSAGES: 'GET',
    SEND_MESSAGE: 'SEND',
    RECEIVE_MESSAGE: 'RECEIVE',
    UPDATE_MESSAGE: 'UPDATE',
    SET_PID: 'SETPID'
};

export const STATUS = {
    SENDING: 'SENDING',
    SENT: 'SENT',
    RECEIVED: 'RECEIVED',
    ERROR: 'ERROR'
}

export const CONTENTTYPE = {
    TEXT: 'text',
    IMAGE: 'image',
    AUDIO: 'audio',
    LIST: 'list'
}

export const MESSAGETYPE = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing'
}

export const appReducer = (state = DEFAULT_STATE, action) => {
    const messages = [ ...state.messages ];

    switch(action.type) {
        case ACTIONS.GET_MESSAGES:
            console.log(action)
            return { ...state, messages: action.messages, suggestions: [], isLoading: false };

        case ACTIONS.SEND_MESSAGE:
            messages.push(action.message);
            return { ...state, messages, suggestions: [], isLoading: false };

        case ACTIONS.UPDATE_MESSAGE:
            console.log("UPDATING");
            console.log(action);
            const updatedMsgs = messages.map(msg => msg._id === action._id ? {...msg, 
                contentType: action.contentType || msg.contentType, 
                status: action.status,
                text: action.text || msg.text
            } : msg);
            localStorage.setItem("msg_history", JSON.stringify(updatedMsgs));
            return { ...state, messages: updatedMsgs, isLoading: false };

        case ACTIONS.RECEIVE_MESSAGE:
            action.messages.forEach(m => {
                messages.push({
                    _id: uuidv4(),
                    text: m.msg.text,
                    status: STATUS.RECEIVED,
                    contentType: m.msg.type,
                    type: MESSAGETYPE.INCOMING,
                    timestamp: new Date().getTime()
                });
            });

            localStorage.setItem("msg_history", JSON.stringify(messages));
            return { ...state, messages, suggestions: action.messages[0].suggestions || [], isLoading: false };

        default:
            return state;
    }
}

export function getMessages(renew = false) {
    return dispatch => {
        authorize(renew).then(t => {
            getWS(dispatch, true);
            fetch().then(r => {
                r.data.msgs.forEach(m => {
                    const timestamp = new Date(m.timestamp);
                    m.timestamp = timestamp.setHours(timestamp.getHours() + 12);
                    if (m.contentType === "text") {
                        if (m.text.toLowerCase().match(/\.(jpeg|jpg|png|gif|svg)/g)) {
                            m.contentType = "image"
                        } else if (m.text.toLowerCase().match(/\.(mp3|wav|ogg|flav|mp4)/g)) {
                            m.contentType = "audio"
                        }
                    }
                });
                dispatch({
                    type: ACTIONS.GET_MESSAGES,
                    messages: r.data.msgs
                })
            });
        });
    }
}

export function keepAlive() {
    return dispatch => {
        getWS(dispatch).then(w => {
            w.send(JSON.stringify({
                action: "PING",
            })
        )})
    }
}

export function sendMessage(text) {
    const messageId = uuidv4();
    return dispatch => {
        dispatch({
            type: ACTIONS.SEND_MESSAGE,
            message: {
                _id: messageId,
                text,
                status: STATUS.SENDING,
                contentType: CONTENTTYPE.TEXT,
                type: MESSAGETYPE.OUTGOING,
                timestamp: new Date().getTime()
            }
        });

        getWS(dispatch).then(w => {
            w.send(JSON.stringify({
                action: "MESSAGE",
                text
            }));
            dispatch([{
                type: ACTIONS.UPDATE_MESSAGE,
                _id: messageId,
                status: STATUS.RECEIVED
            }]);
        });
    }
}

export function sendFile(file, isAudio) {
    const messageId = uuidv4();
    const formData = new FormData();
    return dispatch => {
        dispatch({
            type: ACTIONS.SEND_MESSAGE,
            message: {
                _id: messageId,
                text: isAudio ? 'Enviando áudio...' : 'Enviando arquivo...',
                status: STATUS.SENDING,
                contentType: CONTENTTYPE.TEXT,
                type: MESSAGETYPE.OUTGOING,
                timestamp: new Date().getTime()
            }
        });

        authorize().then(t => {
            formData.append("file", file);
            api.post('upload', formData, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                    Authorization: t
                }
            }).then(r => {
                getWS(dispatch).then(w => {
                    w.send(JSON.stringify({
                        action: "MESSAGE",
                        text: r.data.files[0].url,
                        contentType: isAudio ? CONTENTTYPE.AUDIO : CONTENTTYPE.TEXT
                    }));
                    dispatch([{
                        type: ACTIONS.UPDATE_MESSAGE,
                        _id: messageId,
                        status: STATUS.RECEIVED,
                        contentType: isAudio ? CONTENTTYPE.AUDIO : CONTENTTYPE.IMAGE,
                        text: r.data.files[0].url
                    }]);
                });
            });
        });
    }
}

const receiveMessage = (msg, dispatch) => {
    console.log(msg);
    switch (msg.action) {
        case "SENT":
            break;
        case "PING":
        case "PONG":
            break;
        case "MESSAGE":
            dispatch([{
                type: ACTIONS.RECEIVE_MESSAGE,
                messages: [
                    {
                        msg: msg.msg,
                        suggestions: msg.suggestions
                    }
                ]
            }]);
            break;
        default:
            break;
    } 
}

const authorize = (renew = false) => {
    return new Promise((res, rej) => {
        if (renew) localStorage.removeItem("token");
        if (!localStorage.getItem("bid")) localStorage.setItem("bid", uuidv4());
        const token = (localStorage.getItem("token") && !isTokenExpired(localStorage.getItem("token"))) ? localStorage.getItem("token") : null;
        let pid = localStorage.getItem("pid") || null;
        if (!token) {
            api.get("/authorization", {
                headers: {
                    "X-Api-Key": `${process.env.REACT_APP_BOT}-${process.env.REACT_APP_STAGE}`,
                    "Authorization": localStorage.getItem("bid"),
                    "Partner": pid
                }
            }).then(r => {
                localStorage.setItem("token", r.data.token);
                res(r.data.token);
            }).catch((e) => {
                
            });
        } else {
            res(token);
        }
    });
}

const fetch = () => {
    return api.get("/fetch", { headers: { "Authorization": localStorage.getItem("token") }});
}

const getWS = (dispatch, recreate = false) => {
    return new Promise((res, rej) => {
        if (ws && ws.readyState === 1 && recreate) ws.close();
        if (!ws || ws.readyState !== 1 || recreate) {
            ws = new WebSocket(`${process.env.REACT_APP_WSS}?Auth=${localStorage.getItem("token")}`);
            ws.onopen = () => { 
                console.log("Connected!"); 
                res(ws); 
            };
            ws.onerror = (e) => { 
                authorize().then(r => {
                    getWS(dispatch).then(f => { 
                        res(ws)
                    });
                }); 
            };
            ws.onclose = (e) => { window.location.reload(false); }
            ws.onmessage = (m) => { receiveMessage(JSON.parse(m.data), dispatch); }
        } else {
            res(ws);
        }
    });
}

function isTokenExpired(token) {
    try {
        var exp = (JSON.parse(atob(token.split('.')[1]))).exp;
        var date = new Date(exp * 1000);
        return date <= new Date();
    } catch (err) {
        return true;
    }
}