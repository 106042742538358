import React from 'react';

const LoadingCircle = props => {
    return (
        <div className={`messages-container`}>
            <div className="loading-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default LoadingCircle;